import { GET_USER_ME } from '../types';

// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  switch (action.type) {
    case GET_USER_ME:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};
