/* eslint-disable default-param-last */
import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Grid,
  LinearProgress,
  CircularProgress,
  Select,
  TextField,
  Autocomplete,
  MenuItem,
  FormControl,
  Dialog,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DateFnsUtils from '@date-io/date-fns';
import {
  addDays,
  addWeeks,
  addMonths,
  addYears,
  startOfYear,
  endOfYear,
  endOfWeek,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  format,
  parseISO,
  isAfter,
  isToday,
  endOfDay,
} from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import ReactExport from 'react-excel-exportz';
import { CSVLink } from 'react-csv';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Close as CloseIcon,
  ErrorOutline,
  BlockOutlined,
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
} from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import dealersApi from '../api/dealer.api';
import FilterModal from '../components/Filter';
import truStageApi from '../api/truStage.api';
import reportsApi from '../api/report.api';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const CustomDataGrid = styled('div')({
  '& .custom-dark-theme-header': {
    backgroundColor: '#343a40 !important',
    color: 'white !important',
    '& svg': {
      color: 'white !important',
    },
  },
  '& .custom-link-cell': {
    color: 'blue !important',
    cursor: 'pointer',
  },
});

const StyledComponent = styled('div')(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

const DialogContent = styled('div')(({ theme }) => ({
  // Define root and padding styles
  root: {
    padding: theme.spacing(2),
  },
}));

function TruStageInvitationsList(props) {
  const { filter, jobStatus, getBulkInvitationStatus } = props;
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [status, setStatus] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [resetStatus, setResetStatus] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const dataGridRef = useRef();

  const permissions = useSelector((state) => state.permissions.permissions);

  const columns = [
    {
      headerName: 'Created At',
      valueFormatter: (e) =>
        e.value ? format(parseISO(e.value), 'MM/dd/yyyy') : '',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 105,
    },
    {
      headerName: 'VIN',
      field: 'vin',
      headerClassName: 'custom-dark-theme--header',
      width: 175,
    },
    {
      headerName: 'Name',
      field: 'name',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Account Id',
      field: 'sellingDealerId',
      headerClassName: 'custom-dark-theme--header',
      width: 95,
    },
    {
      headerName: 'Credit Union',
      field: 'sellingDealerName',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Contract Number',
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Email Status',
      renderCell: (e) => {
        const emailStatus = e.value;
        let iconColor;
        let title;
        let icon;
        if (emailStatus) {
          title = `Email ${emailStatus}`;
          if (emailStatus === 'Delivery') {
            iconColor = 'green';
            icon = 'CHECK';
          } else if (emailStatus === 'Sent') {
            iconColor = 'orange';
            icon = 'CHECK';
          } else if (emailStatus === 'Blocked') {
            iconColor = 'red';
            icon = 'BLOCK';
          } else if (emailStatus === 'Bounce') {
            iconColor = 'orange';
            icon = 'CANCEL';
          } else {
            iconColor = 'red';
            icon = 'CANCEL';
          }
        } else {
          iconColor = 'grey';
          title = 'Email Status Not Available';
          icon = 'ERROR';
        }
        return (
          <Tooltip title={title} arrow>
            <div>
              {icon === 'CHECK' ? (
                <CheckCircleRoundedIcon style={{ fill: iconColor }} />
              ) : icon === 'BLOCK' ? (
                <BlockOutlined style={{ fill: iconColor }} />
              ) : icon === 'CANCEL' ? (
                <CancelRoundedIcon style={{ fill: iconColor }} />
              ) : (
                <ErrorOutline style={{ fill: iconColor }} />
              )}
            </div>
          </Tooltip>
        );
      },
      field: 'emailStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Email Open',
      renderCell: (e) => {
        return e.value ? (
          <Tooltip title="Yes">
            <CheckCircleRoundedIcon style={{ fill: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <ErrorOutline style={{ fill: 'grey' }} />
          </Tooltip>
        );
      },
      field: 'emailOpened',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 100,
    },
    {
      headerName: 'Link Open',
      renderCell: (e) => {
        return e.value ? (
          <Tooltip title="Yes">
            <CheckCircleRoundedIcon style={{ fill: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <ErrorOutline style={{ fill: 'grey' }} />
          </Tooltip>
        );
      },
      field: 'linkOpened',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 100,
    },
    {
      headerName: 'Signup',
      renderCell: (e) => {
        return e.value && e.value.toLowerCase() === 'accepted' ? (
          <Tooltip title="Yes">
            <CheckCircleRoundedIcon style={{ fill: 'green' }} />
          </Tooltip>
        ) : (
          <Tooltip title="No">
            <ErrorOutline style={{ fill: 'grey' }} />
          </Tooltip>
        );
      },
      field: 'status',
      headerClassName: 'custom-dark-theme--header',
      width: 100,
    },
    {
      headerName: 'Email Data',
      headerClassName: 'custom-dark-theme--header',
      cellClassName: 'custom-link-cell',
      width: 100,
      field: 'logReference',
      renderCell: () => {
        return 'View';
      },
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isEmailDataLoading, setIsEmailDataLoading] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState(null);
  // const classes = useStyles();
  const [orignalDataSource, setOrignalDataSource] = useState([]);
  const [isFromBulkInvite, setIsFromBulkInvite] = useState(
    !!(filter && filter.bulkInviteRef)
  );
  const [appliedFilter, setAppliedFilter] = useState(filter || {});
  const previousFilter = usePrevious(appliedFilter);
  const [filterDisplay, setFilterDisplay] = useState(
    filter ? 'Filter Applied' : ''
  );
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const csvLinkRef = useRef();
  const [failedData, setFailedData] = useState([]);
  // const [emailData, setEmailData] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [emailEventBody, setEmailEventBody] = useState(null);
  const [emailViewedText, setEmailViewedText] = useState(null);
  const [isEmailDataClicked, setIsEmailDataClicked] = useState(false);
  const [emailTitleText, setEmailTitleText] = useState('N/A');
  const [tabValue, setTabValue] = useState(false);
  const [crntDate, setCrntDate] = useState(new Date());
  const [disableDate, setDisableDate] = useState(new Date());
  const [disableNext, setDisableNext] = useState(false);
  const [dealerId, setDealerId] = useState('');
  const [dealerIds, setDealerIds] = useState([]);
  const [selectedDealers, setSelectedDealers] = useState([]);
  const updateDateFilters = (tab, currentDate) => {
    let startDate;
    let endDate;

    switch (tab) {
      case 0: // Daily
        startDate = currentDate;
        endDate = currentDate;
        break;
      case 1: // Weekly
        startDate = startOfWeek(currentDate);
        endDate = endOfWeek(currentDate);
        break;
      case 2: // Monthly
        startDate = startOfMonth(currentDate);
        endDate = endOfMonth(currentDate);
        break;
      case 3: // Yearly
        startDate = startOfYear(currentDate);
        endDate = endOfYear(currentDate);
        break;
      default:
        break;
    }
    setDisableDate(endDate);
    setFilterAfterDate(startDate);
    setApplyQuickFilter(true);
    setFilterBeforeDate(endDate);
    setApplyQuickFilter(true);
  };

  const fetchEmailData = async (requestId, email) => {
    try {
      setIsEmailDataLoading(true);
      const params = {
        logReferenceId: requestId,
        email,
      };
      const response = await reportsApi.getInvitationDetails(params, dispatch);
      if (response.emailEventBody) {
        // setEmailTitleText('Email Content');
        setEmailTitleText(response.emailEventBody.subject);
        setEmailEventBody(response.emailEventBody.body);
      } else {
        setEmailEventBody('');
      }
      setEmailViewedText(response.emailViewed);
    } catch (error) {
      setEmailEventBody('');
      setEmailViewedText('N/A');
      setEmailTitleText('N/A');
    } finally {
      setIsEmailDataLoading(false);
    }
  };

  async function fetchData(
    skip = dataSource.length,
    limit = pageSize,
    pipeLine = {}
  ) {
    const params = {
      skip,
      limit,
      filter: appliedFilter
        ? JSON.stringify({ ...pipeLine, ...appliedFilter })
        : JSON.stringify(pipeLine),
    };
    return truStageApi.getTruStageInvitations(params, dispatch);
  }

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }
  const setMessagAndEmailStatus = (data) => {
    const modifiedData = data.map((obj) => {
      // const smsSent = obj.message.includes('SMS_NOTIFICATION_SENT');
      // const delaySmsSent = obj.message.includes('SMS_NOTIFICATION_SENT');
      return {
        ...obj,
        // messageSent: smsSent || delaySmsSent,
        // emailSent: obj.message.includes('EMAIL_NOTIFICATION_SENT'),
      };
    });

    return modifiedData;
  };

  useEffect(() => {
    function checkIfDataPresent() {
      const totalDataExpected = (page + 1) * pageSize;
      const startIndex = page * pageSize;
      return (
        dataSource &&
        dataSource.length > startIndex &&
        (rowCount > totalDataExpected
          ? dataSource.length >= totalDataExpected
          : true)
      );
    }

    const loadData = async (appliedFilterUpdated) => {
      if (!checkIfDataPresent() || appliedFilterUpdated) {
        if (appliedFilterUpdated && page !== 0) {
          setDataSource([]);
          return;
        }
        setIsLoading(true);
        const reports = await fetchData(
          appliedFilterUpdated ? 0 : dataSource.length
        );
        if (reports && reports.data) {
          const modifiedRes = setMessagAndEmailStatus(reports.data);
          if (appliedFilterUpdated) {
            setDataSource(
              modifiedRes.map((item, index) => {
                return { ...item, _gridId: index };
              })
            );
          } else {
            updateDataSource(reports.data);
          }
          if (reports.totalCount !== rowCount) {
            setRowCount(reports.totalCount);
          }
        }
        setIsLoading(false);
      }
    };
    const appliedFilterUpdated =
      JSON.stringify(appliedFilter) !== JSON.stringify(previousFilter);
    loadData(appliedFilterUpdated);
  }, [page, pageSize, appliedFilter, dispatch]);

  useEffect(() => {
    if (isFromBulkInvite && jobStatus && !(jobStatus._id || jobStatus.error)) {
      if (getBulkInvitationStatus) {
        getBulkInvitationStatus(filter.bulkInviteRef.$eq);
      }
    }
  }, [jobStatus]);

  useEffect(() => {
    if (dataSource.length === 0) {
      setPage(0);
    }
  }, [dataSource]);

  useEffect(() => {
    if (failedData.length) {
      setTimeout(() => {
        csvLinkRef.current.link.click();
      }, 0);
    }
  }, [failedData]);

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    const filterStartDate = new Date(filterAfterDate);
    // const filterEndDate = new Date(filterBeforeDate);
    let dateDisplay = '';
    if (filterAfterDate) {
      newFilter.createdAt = { $gte: filterStartDate };
      dateDisplay = `Dates: ${format(filterStartDate, 'MM/dd/yyyy')}`;
    }
    if (filterBeforeDate) {
      const filterEndDate = new Date(filterBeforeDate);
      filterEndDate.setHours(23, 59, 59, 999); // Set time to 11:59:59.999 pm
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: filterEndDate,
      };

      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(filterEndDate, 'MM/dd/yyyy')}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(filterEndDate, 'MM/dd/yyyy')}`;
      }
    }

    if (status) {
      newFilter.status = { $eq: `${status}` };
    }
    if (dealerId && dealerId.length > 0) {
      newFilter.sellingDealerId = { $in: dealerId };
    }

    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setAppliedFilter(newFilter);
    if (displays.length) setFilterDisplay(displays.join(' '));
    else setFilterDisplay('Filter Applied');
  };

  useEffect(() => {
    setDisableNext(isAfter(disableDate, new Date()) || isToday(disableDate));
  }, [disableDate]);

  useEffect(() => {
    updateDateFilters(tabValue, crntDate);
  }, [tabValue, crntDate]);

  useEffect(() => {
    if (applyQuickFilter && !!filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate, filterBeforeDate]);

  async function fetchAndSetDealerIds(
    skip = 0,
    limit = 1000,
    pipeLine = {
      vcoRegistrationPreference: 'C',
      reportingEnabled: true,
    }
  ) {
    const params = {
      skip,
      limit,
      filter: JSON.stringify(pipeLine),
    };
    const response = await dealersApi.getAllUserDealer(params, dispatch);
    if (response && response.data) {
      const dealers = response.data.map((dealer) => ({
        dealerId: dealer.dealerId,
        name: dealer.name,
      }));
      setDealerIds(dealers);
    }
  }

  useEffect(() => {
    const fetchDealerData = async () => {
      await fetchAndSetDealerIds();
    };
    fetchDealerData();
  }, []);

  async function fetchDataInBatches(
    index = 0,
    limit = 50,
    allReports,
    pipeLine
  ) {
    const report = await fetchData(index * limit, limit, pipeLine);
    const modifiedRes = setMessagAndEmailStatus(report.data);
    allReports = allReports.concat(modifiedRes); // eslint-disable-line no-param-reassign
    if (report.currentPage !== report.pageCount) {
      setDownloadMsg(
        `Downloading ${report.currentPage + 1} of ${report.pageCount} Pages`
      );
      return fetchDataInBatches(report.currentPage, limit, allReports);
    }
    return allReports;
  }

  const downloadResults = async () => {
    setIsDownloading(true);
    setDownloadData([]);
    setDownloadMsg(`Downloading 1 of ${Math.round(rowCount / 50)} Pages`);
    const reports = await fetchDataInBatches(0, 50, []);
    setDownloadData(reports);
    setIsDownloading(false);
    setDownloadMsg(null);
  };

  const handlePageSizeChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const resetFilters = async () => {
    setTabValue(false);
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
    // setStatus('');
    const filterData = {};
    if (status) {
      filterData.status = appliedFilter.status;
    }
    if (dealerId && dealerId.length > 0) {
      setDealerId(dealerId);
      filterData.sellingDealerId = appliedFilter.sellingDealerId;
    } else {
      setSelectedDealers([]);
      setDealerId('');
    }
    setAppliedFilter(filterData);
    setPage(0);
    setIsFromBulkInvite(false);
    // setSelectedDealers([]);
    await fetchAndSetDealerIds();
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    if (dealerId || status) {
      saveFilters();
    }
  }, [dealerId, status]);

  const handleDealerIdsChange = (event, options) => {
    setSelectedDealers(options);
    const dealerIdsData = options.map((option) => option.dealerId);
    setDealerId(dealerIdsData);
  };

  const handleCellClick = (cellParams) => {
    setIsEmailDataClicked(false);
    if (cellParams.field === 'reminderLog') {
      if (cellParams.value && cellParams.value.length > 0) {
        const parsedBody = JSON.stringify(cellParams.value);
        setModalBody(parsedBody);
        setOpenModal(true);
      }
    } else if (cellParams.field === 'logReference') {
      setSelectedRowData(cellParams.row);

      // Set isEmailDataClicked to true when clicking on the email data column
      setIsEmailDataClicked(true);

      // Fetch email data
      if (
        selectedRowData &&
        selectedRowData.logReference &&
        selectedRowData.email
      ) {
        const fetchDataAndOpenModal = async () => {
          try {
            await fetchEmailData(
              cellParams.row.logReference,
              cellParams.row.email
            );
          } finally {
            // After email data is fetched and loading is complete, open the modal
            setOpenModal(true);
          }
        };

        // Call the async function
        fetchDataAndOpenModal();
      }
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setQrCodeData(null);
  };

  const applyCustomFilter = (value) => {
    let data = orignalDataSource;
    if (orignalDataSource && !orignalDataSource.length) {
      setOrignalDataSource(dataSource);
      data = dataSource;
    }
    if (data.length === rowCount) {
      if (value && value.length) {
        data = data.filter((item) => {
          return item.status === value;
        });
      }
      setDataSource(data);
    } else {
      const filterValues = { ...appliedFilter };
      if (
        value &&
        value.length &&
        appliedFilter &&
        appliedFilter.status !== value
      ) {
        filterValues.status = value;
      } else {
        delete filterValues.status;
      }
      setAppliedFilter(filterValues);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    let newDate;
    switch (newValue) {
      case 0: // Daily
        newDate = new Date();
        break;
      case 1: // Weekly
        newDate = startOfWeek(new Date());
        break;
      case 2: // Monthly
        newDate = startOfMonth(new Date());
        break;
      case 3: // Yearly
        newDate = startOfYear(new Date());
        break;
      default:
        newDate = new Date();
        break;
    }
    setCrntDate(newDate);
    updateDateFilters(newValue, newDate);
  };

  const handleNext = () => {
    setCrntDate((prevDate) => {
      let newDate;
      switch (tabValue) {
        case 0: // Daily
          newDate = addDays(prevDate, 1);
          break;
        case 1: // Weekly
          newDate = addWeeks(prevDate, 1);
          break;
        case 2: // Monthly
          newDate = addMonths(prevDate, 1);
          break;
        case 3: // Yearly
          newDate = addYears(prevDate, 1);
          break;
        default:
          newDate = prevDate;
          break;
      }
      return newDate;
    });
  };

  const handlePrev = () => {
    setCrntDate((prevDate) => {
      let newDate;
      switch (tabValue) {
        case 0: // Daily
          newDate = addDays(prevDate, -1);
          break;
        case 1: // Weekly
          newDate = addWeeks(prevDate, -1);
          break;
        case 2: // Monthly
          newDate = addMonths(prevDate, -1);
          break;
        case 3: // Yearly
          newDate = addYears(prevDate, -1);
          break;
        default:
          newDate = prevDate;
          break;
      }
      return newDate;
    });
  };

  const downloadFailedResults = async () => {
    setIsDownloading(true);
    if (dataSource.length === rowCount) {
      setFailedData(
        dataSource.filter((item) => {
          return item.status === 'Failure';
        })
      );
    } else {
      const reports = await fetchDataInBatches(0, 50, [], {
        status: 'Failure',
      });
      setFailedData(reports);
    }
    setIsDownloading(false);
  };

  const handleClearSelection = async () => {
    setStatus('');
    setResetStatus(true);
  };

  useEffect(() => {
    if (resetStatus) {
      setResetStatus(false);
      saveFilters();
    }
  }, [resetStatus]);

  return (
    <Grid container className="reports">
      <Grid item sm={12}>
        {isFromBulkInvite && jobStatus.totalRows ? (
          <div>
            <Button variant="contained" onClick={() => applyCustomFilter('')}>
              Total:&nbsp;
              {jobStatus.totalRows}
            </Button>
            <Button
              variant="contained"
              onClick={() => applyCustomFilter('Success')}
              className="btn btn-success"
              style={{
                color: '#fff',
                backgroundColor: '#28a745',
                borderColor: '#28a745',
                margin: '0 10px',
              }}
            >
              Success:&nbsp;
              {jobStatus.success}
            </Button>
            <Button
              variant="contained"
              onClick={() => applyCustomFilter('Failure')}
              className="btn btn-warning"
              style={{
                color: '#212529',
                backgroundColor: '#ffc107',
                borderColor: '#ffc107',
              }}
            >
              Failure:&nbsp;
              {jobStatus.failure}
            </Button>
          </div>
        ) : (
          ''
        )}
        <div className="d-flex flex-column align-items-end mb-2">
          <div className="d-flex justify-content-between align-items-center w-100 mb-2 flex-wrap">
            <div className="d-flex align-items-center">
              <h2 className="mr-2" style={{ marginRight: '20px' }}>
                TruStage Invitations
              </h2>
              {permissions.INVITATIONS_LOGS.WRITE && (
                <Button
                  disabled={isDownloading}
                  color="primary"
                  variant="outlined"
                  onClick={downloadResults}
                >
                  {downloadMsg ? (
                    <>
                      <span style={{ fontSize: '12px' }}>{downloadMsg}</span>
                      <CircularProgress
                        style={{ height: '25px', width: '25px' }}
                      />
                    </>
                  ) : (
                    'Download details'
                  )}
                </Button>
              )}
              &nbsp;
              {isFromBulkInvite && jobStatus.failure ? (
                <Button
                  disabled={isDownloading}
                  color="primary"
                  variant="outlined"
                  onClick={downloadFailedResults}
                >
                  {isDownloading ? (
                    <>
                      <span style={{ fontSize: '12px' }}>
                        Downloading&nbsp;
                      </span>
                      <CircularProgress
                        style={{ height: '25px', width: '25px' }}
                      />
                    </>
                  ) : (
                    'Download Failed Invites'
                  )}
                </Button>
              ) : (
                ''
              )}
              <CSVLink
                filename="report.csv"
                data={failedData}
                ref={csvLinkRef}
              />
              {downloadData && downloadData.length ? (
                <ExcelFile hideElement>
                  <ExcelSheet data={downloadData} name="InvitationLogs">
                    <ExcelColumn label="vin" value="vin" />
                    <ExcelColumn label="name" value="name" />
                    <ExcelColumn label="email" value="email" />
                    <ExcelColumn
                      label="sellingDealerId"
                      value="sellingDealerId"
                    />
                    <ExcelColumn label="dealerName" value="sellingDealerName" />
                    <ExcelColumn label="createdAt" value="createdAt" />
                    <ExcelColumn label="email status" value="emailStatus" />
                    <ExcelColumn
                      label="emailOpened"
                      value={(col) => (col.emailOpened ? 'Yes' : 'No')}
                    />
                    <ExcelColumn
                      label="linkOpened"
                      value={(col) => (col.linkOpened ? 'Yes' : 'No')}
                    />
                    <ExcelColumn
                      label="signup"
                      value={(col) =>
                        col.status.toLowerCase() === 'accepted' ? 'Yes' : 'No'
                      }
                    />
                  </ExcelSheet>
                </ExcelFile>
              ) : (
                ''
              )}
            </div>
            <div className="d-flex align-items-center">
              <div>
                <FormControl
                  fullWidth
                  style={{
                    display: 'flex',
                    marginLeft: '50px',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ position: 'relative' }}>
                    <Select
                      displayEmpty
                      labelId="select-status"
                      id="select"
                      value={status}
                      onChange={handleStatusChange}
                      style={{
                        width: '200px',
                        paddingRight: '32px', // Adjust to accommodate the close icon
                        marginRight: '60px',
                      }}
                      MenuProps={{ PaperProps: { style: { width: '200px' } } }} // Adjust menu width if needed
                    >
                      <MenuItem disabled value="">
                        <em>Select Status</em>
                      </MenuItem>
                      <MenuItem key="Accepted" value="Accepted">
                        Accepted
                      </MenuItem>
                      <MenuItem key="Pending" value="Pending">
                        Pending
                      </MenuItem>
                    </Select>
                    {status && (
                      <IconButton
                        style={{
                          position: 'absolute',
                          right: '30px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                        size="small"
                        onClick={handleClearSelection}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </div>
                </FormControl>
              </div>
              <div>
                {dealerIds.length > 0 && (
                  <Autocomplete
                    multiple
                    id="select-dealerId"
                    options={dealerIds}
                    autoHighlight
                    getOptionLabel={(option) =>
                      `${option.name} (${option.dealerId})`
                    }
                    style={{ width: 300 }}
                    // value={
                    //   dealerId === ''
                    //     ? null
                    //     : dealerIds.find(
                    //         (option) => option.dealerId === dealerId
                    //       )
                    // }
                    value={selectedDealers}
                    onChange={handleDealerIdsChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a dealer"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <Tabs
                value={tabValue !== null ? tabValue : false}
                onChange={handleTabChange}
              >
                <Tab label="Daily" value={0} />
                <Tab label="Weekly" value={1} />
                <Tab label="Monthly" value={2} />
                <Tab label="Yearly" value={3} />
              </Tabs>
              {appliedFilter && !Object.keys(appliedFilter).length}
              <FilterModal
                currentFilters={appliedFilter}
                onSave={saveFilters}
                itemName="Reports"
                resetFilters={resetFilters}
                filterDisplay={filterDisplay}
                tabValue={tabValue}
                // filterDisplay={tabValue === null ? filterDisplay : undefined}
              >
                <div style={{ marginBottom: '16px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      utils={DateFnsUtils}
                      label="After Date"
                      variant="inline"
                      className="mr-2"
                      fullWidth
                      id="after-date-filter"
                      value={filterAfterDate}
                      onChange={setFilterAfterDate}
                      autoOk
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      utils={DateFnsUtils}
                      label="Before Date"
                      variant="inline"
                      className="mr-2"
                      fullWidth
                      id="before-date-filter"
                      value={filterBeforeDate}
                      onChange={(selectedDate) => {
                        // Set default time to 11:59 pm if date is selected
                        if (selectedDate) {
                          const modifiedDate = endOfDay(selectedDate);
                          setFilterBeforeDate(modifiedDate);
                        } else {
                          setFilterBeforeDate(selectedDate);
                        }
                      }}
                      autoOk
                    />
                  </LocalizationProvider>
                </div>
              </FilterModal>
            </div>
          </div>
          <div className="d-flex align-items-center">
            {tabValue !== false && (
              <>
                <Button
                  onClick={handlePrev}
                  variant="contained"
                  color="primary"
                  style={{ marginRight: '10px' }}
                >
                  Prev
                </Button>
                {filterDisplay.replace('Dates: ', '')}
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                  disabled={disableNext}
                  style={{ marginLeft: '10px' }}
                >
                  Next
                </Button>
              </>
            )}
          </div>
        </div>

        {/* </h2> */}
        {isLoading ? (
          <LinearProgress style={{ height: '1rem' }} color="secondary" />
        ) : (
          <StyledComponent>
            <CustomDataGrid>
              <div
                style={{ height: '75vh', width: '100%' }}
                className={StyledComponent.root}
              >
                <DataGrid
                  paginationModel={{ page, pageSize }}
                  pagination
                  pageSizeOptions={PAGINATION_OPTIONS}
                  onPageSizeChange={handlePageSizeChange}
                  rowCount={rowCount}
                  getRowId={(row) => row._gridId}
                  rows={dataSource}
                  columns={columns}
                  onPaginationModelChange={handlePageChange}
                  onCellClick={handleCellClick}
                  ref={dataGridRef}
                  className={CustomDataGrid.root}
                  componentsOverrides={{
                    Header: {
                      cell: 'custom-dark-theme--header',
                    },
                    Cell: {
                      cell: 'custom-link-cell',
                    },
                  }}
                />
              </div>
            </CustomDataGrid>
          </StyledComponent>
        )}
        {isEmailDataLoading && (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 9999, // Ensure it's on top of everything
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginBottom: '8px',
              }}
            >
              Loading Email Data...
            </span>
            <CircularProgress style={{ height: '50px', width: '50px' }} />
          </div>
        )}

        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
          fullWidth={isEmailDataClicked}
          maxWidth={isEmailDataClicked ? 'md' : 'lg'}
          style={{
            overflow: 'hidden', // Hide the dialog scrolling
          }}
        >
          <CustomDialogTitle>
            <Typography variant="h6">
              {isEmailDataClicked ? emailTitleText : 'Value'}
            </Typography>
            <IconButton
              aria-label="close"
              className={CustomDialogTitle.closeButton}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </CustomDialogTitle>
          <DialogContent
            style={{
              marginLeft: '20px',
              marginRight: '20px',
              minHeight: isEmailDataClicked ? '500px' : 'auto', // Set your preferred minHeight
              overflow: 'auto',
            }}
          >
            <pre>{!isEmailDataClicked && modalBody}</pre>
            {qrCodeData ? (
              <div
                style={{
                  padding: '20px 0px',
                }}
              >
                <div>QR Code:</div>
                <div>
                  <div style={{ textAlign: 'center' }}>
                    <img src={`data:image/png;base64,${qrCodeData}`} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {emailEventBody && isEmailDataClicked && !isEmailDataLoading && (
              <div
                className={DialogContent.iframeContainer}
                style={{ overflow: 'hidden' }}
              >
                <iframe
                  title="Email Event Body"
                  srcDoc={emailEventBody}
                  style={{
                    border: 'none',
                    width: '100%',
                    height: '500px',
                    overflow: 'hidden',
                  }}
                />
              </div>
            )}

            {emailViewedText && isEmailDataClicked && (
              <div style={{ marginBottom: '20px' }}>
                <Typography variant="subtitle1">Email Viewed:</Typography>
                <Typography variant="body1">{emailViewedText}</Typography>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}

export default TruStageInvitationsList;
