import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';
import reportApi from '../api/report.api';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>VCP Mule API Logs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function VCPMuleApiLogs({ appliedFilter, viewHeight = '50vh' }) {
  const columns = [
    {
      headerName: 'Date',
      renderCell: (c) =>
        moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'Name',
      render: (e) => e.name,
      field: 'name',
      headerClassName: 'custom-dark-theme--header',
      width: 400,
    },
    {
      headerName: 'Status Code',
      render: (e) => e.statusCode,
      field: 'statusCode',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'Endpoint',
      render: (e) => e.endpoint,
      field: 'endpoint',
      headerClassName: 'custom-dark-theme--header',
      width: 450,
    },
    {
      headerName: 'Request/Response',
      renderCell: (c) => {
        return (
          <>
            <JSONViewer
              title="Request"
              color="primary"
              jsonData={c.row.payload ? c.row.payload : ''}
            />
            <JSONViewer
              title="Response"
              color="secondary"
              jsonData={c.row.response ? c.row.response : ''}
            />
          </>
        );
      },
      field: 'request',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[1]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item) => {
      return { ...item };
    });
    setDataSource(_updatedDataSource);
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: appliedFilter ? JSON.stringify(appliedFilter) : {},
    };
    setIsLoading(true);

    return reportApi.getVcpMuleApiLogs(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.totalItems;
        const currentPageRecords = reports.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, appliedFilter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            paginationMode="server"
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default VCPMuleApiLogs;
