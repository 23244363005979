import React from 'react';
import VCPScheduleJobsList from '../components/VCPScheduledJobsList';

function VCPScheduleJobs() {
  return (
    <div>
      <div className="row my-2 ">
        <VCPScheduleJobsList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default VCPScheduleJobs;
