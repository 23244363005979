import React from 'react';
import SuperUserList from '../components/SuperUserList';

function SuperUserManager() {
  return (
    <div>
      <div>
        <h3 className="d-flex align-content-center align-items-center mb-0">
          <div>Admin Users</div>
        </h3>
      </div>
      <div className="row my-2 ">
        <SuperUserList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default SuperUserManager;
