import api from '.';

const REPORT_URL = `/admin/report`;

const getScheduleJobs = async (dispatch, params) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/scheduled-jobs/list`,
    null,
    params
  );
};

const getScheduleJobArchives = async (dispatch, params) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/scheduled-job-archives/list`,
    null,
    params
  );
};

const deleteScheduledJob = async (dispatch, id) => {
  return api.pocketDriveApi(
    dispatch,
    'delete',
    `${REPORT_URL}/scheduled-jobs/${id}`
  );
};

const runScheduledJob = async (dispatch, id) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/scheduled-jobs/${id}/run`
  );
};

const rerunScheduledJobArchive = async (dispatch, id) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/scheduled-job-archives/${id}/rerun`
  );
};

export default {
  getScheduleJobs,
  getScheduleJobArchives,
  deleteScheduledJob,
  runScheduledJob,
  rerunScheduledJobArchive,
};
