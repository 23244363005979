import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-json-view-lite/dist/index.css';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Close, CopyAllTwoTone, LogoDevOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

function JSONViewer(props) {
  const { title, jsonData, color = 'primary' } = props;
  const [show, setShow] = useState(false);

  const onClose = () => setShow(false);
  const onCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(jsonData));
  };

  const permissions = useSelector((state) => state.permissions.permissions);

  return (
    <>
      <IconButton
        style={{ display: permissions.SQL_EDITOR.READ === true ? '' : 'none' }}
        onClick={() => setShow(true)}
      >
        <LogoDevOutlined color={color} />
      </IconButton>
      <Dialog open={show} onClose={onClose}>
        <DialogTitle disableTypography className="d-flex align-items-center">
          <Typography variant="h6" className="flex-grow-1">
            {title}
          </Typography>
          <IconButton onClick={onCopy}>
            <CopyAllTwoTone />
          </IconButton>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <JsonView
            data={jsonData}
            shouldExpandNode={allExpanded}
            style={defaultStyles}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default JSONViewer;
