import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';
import subscriptionsApi from '../api/subscriptions.api';

const dateTimeFormat = process.env.REACT_APP_DATE_TIME_FORMAT;
const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

const LOG_TYPE = {
  All: 'All',
  CybersourcePaymentWebHook: 'CybersourcePaymentWebHook',
  CybersourcePaymentNotification: 'CybersourcePaymentNotification',
  GLOWContractActivation: 'GLOWContractActivation',
  ExternalPaymentUpdate: 'ExternalPaymentUpdate',
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>
        Subscription Callback Logs
      </h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function SubscriptionCallbackLogs({ viewHeight = '50vh' }) {
  const [tableColumns, setTableColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[3]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [filterLogType, setFilterLogType] = useState(LOG_TYPE.All);

  function updateColumns() {
    const columns = [];
    columns.push({
      headerName: 'Date',
      field: 'createdAt',
      renderCell: (c) => {
        return (
          <div>
            <JSONViewer title={c.row.id} jsonData={c.row} />
            {moment(c.row.createdAt).format(dateTimeFormat)}
          </div>
        );
      },
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    });
    columns.push({
      headerName: 'Type',
      renderCell: (c) => {
        return (
          <div>
            <JSONViewer title="Request Body" jsonData={c.row.requestBody} />
            <JSONViewer
              title="Event Body"
              color="secondary"
              jsonData={JSON.parse(c.row.eventBody)}
            />
            {c.row.type}
          </div>
        );
      },
      field: 'type',
      headerClassName: 'custom-dark-theme--header',
      width: 320,
    });
    if (
      filterLogType === LOG_TYPE.CybersourcePaymentWebHook ||
      filterLogType === LOG_TYPE.CybersourcePaymentNotification
    ) {
      columns.push({
        headerName: 'Decision',
        renderCell: (e) =>
          e.row.requestBody.decision ? e.row.requestBody.decision : '',
        field: 'decision',
        headerClassName: 'custom-dark-theme--header',
        width: 90,
      });
      columns.push({
        headerName: 'Code',
        renderCell: (e) =>
          e.row.requestBody.reason_code ? e.row.requestBody.reason_code : '',
        field: 'reasonCode',
        headerClassName: 'custom-dark-theme--header',
        width: 60,
      });
      columns.push({
        headerName: 'Email',
        renderCell: (e) =>
          e.row.requestBody.req_bill_to_email
            ? e.row.requestBody.req_bill_to_email
            : '',
        field: 'email',
        headerClassName: 'custom-dark-theme--header',
        width: 280,
      });
      columns.push({
        headerName: 'Request Id',
        renderCell: (e) =>
          e.row.rootTransactionUuid
            ? e.row.rootTransactionUuid
            : e.row.requestBody.req_transaction_uuid
            ? e.row.requestBody.req_transaction_uuid
            : '',
        field: 'requestId',
        headerClassName: 'custom-dark-theme--header',
        width: 300,
      });
      columns.push({
        headerName: 'Transaction Id',
        renderCell: (e) =>
          e.row.rootGatewayTransactionId
            ? e.row.rootGatewayTransactionId
            : e.row.requestBody.transaction_id
            ? e.row.requestBody.transaction_id
            : '',
        field: 'transactionId',
        headerClassName: 'custom-dark-theme--header',
        width: 190,
      });
      columns.push({
        headerName: 'Reference Id',
        renderCell: (e) =>
          e.row.rootTransactionReference
            ? e.row.rootTransactionReference
            : e.row.requestBody.auth_trans_ref_no
            ? e.row.requestBody.auth_trans_ref_no
            : '',
        field: 'txReference',
        headerClassName: 'custom-dark-theme--header',
        width: 150,
      });
    } else if (filterLogType === LOG_TYPE.GLOWContractActivation) {
      columns.push({
        headerName: 'Contract Number',
        renderCell: (e) =>
          e.row.contractNumber
            ? e.row.contractNumber
            : e.row.requestBody.contractNumber
            ? e.row.requestBody.contractNumber
            : '',
        field: 'contractNumber',
        headerClassName: 'custom-dark-theme--header',
        width: 180,
      });
      columns.push({
        headerName: 'Externa Contract Number',
        renderCell: (e) =>
          e.row.externalContractNumber
            ? e.row.externalContractNumber
            : e.row.requestBody.externalContractNumber
            ? e.row.requestBody.externalContractNumber
            : '',
        field: 'externalContractNumber',
        headerClassName: 'custom-dark-theme--header',
        width: 300,
      });
    } else if (filterLogType === LOG_TYPE.ExternalPaymentUpdate) {
      columns.push({
        headerName: 'Contract Number',
        renderCell: (e) =>
          e.row.contractNumber
            ? e.row.contractNumber
            : e.row.requestBody.contractNumber
            ? e.row.requestBody.contractNumber
            : '',
        field: 'contractNumber',
        headerClassName: 'custom-dark-theme--header',
        width: 180,
      });
      columns.push({
        headerName: 'Offer Id',
        renderCell: (e) =>
          e.row.offerId
            ? e.row.offerId
            : e.row.requestBody.offerId
            ? e.row.requestBody.offerId
            : '',
        field: 'offerId',
        headerClassName: 'custom-dark-theme--header',
        width: 160,
      });
      columns.push({
        headerName: 'Root Tx Id',
        renderCell: (e) =>
          e.row.requestBody.rootTransactionReference
            ? e.row.requestBody.rootTransactionReference
            : '',
        field: 'rootTransactionReference',
        headerClassName: 'custom-dark-theme--header',
        width: 200,
      });
      columns.push({
        headerName: 'Gateway Tx Id',
        renderCell: (e) =>
          e.row.requestBody.gatewayTransactionId
            ? e.row.requestBody.gatewayTransactionId
            : '',
        field: 'gatewayTransactionId',
        headerClassName: 'custom-dark-theme--header',
        width: 260,
      });
    } else {
      columns.push({
        headerName: 'Metadata',
        renderCell: (params) => {
          return (
            <div>
              {params.row.requestBody.decision
                ? `${params.row.requestBody.decision}, `
                : ''}
              {params.row.requestBody.reason_code
                ? `${params.row.requestBody.reason_code}, `
                : ''}
              {params.row.requestBody.req_bill_to_email
                ? `${params.row.requestBody.req_bill_to_email}, `
                : ''}
              {params.row.requestBody.transaction_id
                ? `${params.row.requestBody.transaction_id}, `
                : ''}
              {params.row.requestBody.auth_trans_ref_no
                ? `${params.row.requestBody.auth_trans_ref_no}, `
                : ''}
              {params.row.requestBody.req_transaction_uuid
                ? `${params.row.requestBody.req_transaction_uuid}, `
                : ''}
              {params.row.requestBody.contractNumber
                ? `${params.row.requestBody.contractNumber}, `
                : ''}
              {params.row.requestBody.externalContractNumber
                ? `${params.row.requestBody.externalContractNumber}, `
                : ''}
            </div>
          );
        },
        field: 'metadata',
        headerClassName: 'custom-dark-theme--header',
        width: 900,
      });
    }
    setTableColumns(columns);
  }

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async () => {
    let filterValue = {};
    if (filterLogType !== LOG_TYPE.All) {
      filterValue = { type: { eq: filterLogType } };
    }
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filterValue),
    };
    // setIsLoading(true);

    return subscriptionsApi.getCallbackLogs(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      updateColumns();
      const reports = await fetchReport();
      if (
        reports &&
        reports.data &&
        reports.data.data &&
        reports.data.data.length > 0
      ) {
        updateDataSource(reports.data.data);
        setRowCount(reports.data.totalItems);
      } else {
        setDataSource([]); // Clear data if no new data is returned
        setRowCount(0);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filterLogType]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  function selectTab(type) {
    setDataSource([]);
    setRowCount(0);
    setFilterLogType(type);
  }

  function getTabVariant(type) {
    if (filterLogType === type) {
      return 'contained';
    }
    return 'outlined';
  }

  return (
    <div>
      <div style={{ marginBottom: 5 }} className="d-flex">
        <div>
          <ButtonGroup disableElevation variant="outlined" color="primary">
            <Button
              variant={getTabVariant(LOG_TYPE.All)}
              onClick={() => selectTab(LOG_TYPE.All)}
            >
              All
            </Button>
            {isUK && (
              <Button
                variant={getTabVariant(LOG_TYPE.GLOWContractActivation)}
                onClick={() => selectTab(LOG_TYPE.GLOWContractActivation)}
              >
                GLOW Contract Activation
              </Button>
            )}
            <Button
              variant={getTabVariant(LOG_TYPE.CybersourcePaymentWebHook)}
              onClick={() => selectTab(LOG_TYPE.CybersourcePaymentWebHook)}
            >
              Cybersource Paywebhook
            </Button>
            <Button
              variant={getTabVariant(LOG_TYPE.CybersourcePaymentNotification)}
              onClick={() => selectTab(LOG_TYPE.CybersourcePaymentNotification)}
            >
              Cybersource Notification
            </Button>
            {isUK && (
              <Button
                variant={getTabVariant(LOG_TYPE.ExternalPaymentUpdate)}
                onClick={() => selectTab(LOG_TYPE.ExternalPaymentUpdate)}
              >
                External Payment Update
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={tableColumns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SubscriptionCallbackLogs;
