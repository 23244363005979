import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import {
  DeleteSweepRounded,
  EditNoteRounded,
  RefreshRounded,
} from '@mui/icons-material';
import { Spinner } from 'react-bootstrap';
import { UK, US, MX } from '../assets/images';
import {
  ADMIN_ROLES_LIST,
  PAGINATION_OPTIONS,
  US_REGION_LIST,
} from '../constants';
import userApi from '../api/user.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getIconForRegion(region) {
  switch (region) {
    case 'US':
      return US;
    case 'UK':
      return UK;
    case 'MX':
      return MX;
    default:
      return '';
  }
}

function SuperUserList({ appliedFilter }) {
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[3]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [userId, setUserId] = useState('');
  const [name, setName] = useState('');
  const [adminAssumedRole, setAdminAssumedRole] = useState('');
  const [adminAllowedRegions, setAdminAllowedRegions] = useState([]);
  const [adminRefreshRole, setAdminRefreshRole] = useState(false);
  const permissions = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );

  const [apiResponse, setApiResponse] = useState({
    toastOpen: false,
    message: '',
    success: false,
  });

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        const temp = { userId: item._id, ...item, _id: index + startIndex };
        delete temp.notificationPreferences;
        delete temp.blockedNotifications;
        delete temp.lockedVehicles;
        return temp;
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    // eslint-disable-next-line no-unused-vars
    const params = {
      skip,
      limit,
      filter: JSON.stringify({
        ...appliedFilter,
      }),
    };
    setIsLoading(true);

    return userApi.getSuperUserList({}, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.count);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, appliedFilter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApiResponse({
      toastOpen: false,
      message: '',
      success: false,
    });
  };

  const handleEditSave = async () => {
    const updatedData = {
      userId,
      data: {
        name,
        adminAllowedRegions,
        adminAssumedRole,
        adminRefreshRole,
      },
    };
    setIsEditing(true);
    const response = await userApi.updateSuperUser(dispatch, updatedData);
    setIsEditing(false);
    const message =
      response && response.success ? 'Update Successfully' : 'Update Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    setOpenEditModal(false);
    window.location.reload();
  };

  const handleEditClick = (row) => {
    setName(row.name || '');
    setAdminAllowedRegions(row.adminAllowedRegions || []);
    setAdminAssumedRole(row.adminAssumedRole || '');
    if (row.adminRefreshRole === false || row.adminRefreshRole === 'false') {
      setAdminRefreshRole(false);
    } else {
      setAdminRefreshRole(true);
    }
    setAdminRefreshRole(row.adminRefreshRole);
    setUserId(row.userId || '');
    setOpenEditModal(true);
  };

  const handleDeleteUser = async (user) => {
    setIsLoading(true);
    const data = { userId: user.userId };
    const response = await userApi.deleteSuperUser(dispatch, data);
    setIsLoading(false);
    const message =
      response && response.success ? 'Deleted Succesfully' : 'Delete Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    window.location.reload();
    setOpenEditModal(false);
  };

  const populateAdminOktaProfile = async (row) => {
    setIsLoading(true);
    const response = await userApi.populateAdminOktaProfile(
      row.userId,
      dispatch
    );
    setIsLoading(false);
    const message =
      response && response.success ? 'Update Successfully' : 'Update Failed';
    setApiResponse({
      toastOpen: true,
      message,
      success: response && response.success,
    });
    window.location.reload();
    setOpenEditModal(false);
  };

  const columns = [
    {
      headerName: 'User Name',
      renderCell: (e) => (
        <div>
          {permissions && permissions.SUPER_ADMIN.WRITE && (
            <JSONViewer title={e.row.name} jsonData={e.row} />
          )}
          {e.row.name}
        </div>
      ),
      field: 'name',
      headerClassName: 'custom-dark-theme--header',
      width: 250,
    },
    {
      headerName: 'Email',
      render: (e) => e.email,
      field: 'email',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Role',
      render: (e) => e.adminAssumedRole,
      field: 'adminAssumedRole',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Created At',
      renderCell: (e) => (e.row.createdAt ? e.row.createdAt : ''),
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Last Logged In',
      renderCell: (e) => (e.row.oktaProfile ? e.row.oktaProfile.lastLogin : ''),
      field: 'lastLoggedIn',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'User Type',
      renderCell: (e) =>
        e.row.oktaProfile &&
        e.row.oktaProfile.profile &&
        e.row.oktaProfile.profile.userType
          ? `${e.row.oktaProfile.profile.userType}, ${e.row.oktaProfile.profile.subUserType}`
          : '',
      field: 'userType',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Country Locale',
      renderCell: (e) =>
        e.row.oktaProfile &&
        e.row.oktaProfile.profile &&
        e.row.oktaProfile.profile.countryLocale
          ? `${e.row.oktaProfile.profile.countryLocale}`
          : '',
      field: 'countryLocale',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
    },
    {
      headerName: 'Regions',
      renderCell(e) {
        if (e.row.adminAllowedRegions && e.row.adminAllowedRegions.length > 0) {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
              {e.row.adminAllowedRegions.map((value) => (
                <Chip
                  avatar={<Avatar alt={value} src={getIconForRegion(value)} />}
                  key={value}
                  label={value}
                  color="primary"
                  size="small"
                  variant="filled"
                />
              ))}
            </Box>
          );
        }
        return '';
      },
      field: 'adminAllowedRegions',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
  ];
  if (permissions && permissions.SUPER_ADMIN.WRITE) {
    columns.push({
      headerName: 'Actions',
      field: 'actions',
      renderCell: (params) => {
        return (
          <div>
            <IconButton onClick={() => populateAdminOktaProfile(params.row)}>
              <RefreshRounded />
            </IconButton>
            <IconButton onClick={() => handleEditClick(params.row)}>
              <EditNoteRounded />
            </IconButton>
            <IconButton onClick={() => handleDeleteUser(params.row)}>
              <DeleteSweepRounded />
            </IconButton>
          </div>
        );
      },
      width: 200,
      disableColumnMenu: true,
    });
  }

  return (
    <div className="container">
      <Snackbar
        open={apiResponse.toastOpen}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div>
          <Alert
            onClose={handleToastClose}
            severity={apiResponse.success ? 'success' : 'error'}
          >
            {apiResponse.message}
          </Alert>
        </div>
      </Snackbar>
      <div
        style={{
          height: '80vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
      <Dialog
        onClose={handleEditModalClose}
        aria-labelledby="customized-dialog-title"
        open={openEditModal}
        maxWidth="md" // Set the desired maximum width
        fullWidth
      >
        <DialogTitle>Edit Super Admin</DialogTitle>
        <DialogContent
          style={{
            marginLeft: '20px',
            marginRight: '20px',
          }}
        >
          <div style={{ marginBottom: '10px' }}>
            <TextField
              id="edit-super-user-id"
              label="Id"
              value={userId}
              disabled
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              id="edit-super-user-name"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <FormControl fullWidth>
              <InputLabel htmlFor="select-role">Role</InputLabel>
              <Select
                labelId="select-role"
                id="edit-super-user-role"
                value={adminAssumedRole}
                onChange={(e) => setAdminAssumedRole(e.target.value)}
              >
                {ADMIN_ROLES_LIST.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={adminRefreshRole}
                    onChange={() => setAdminRefreshRole(!adminRefreshRole)}
                  />
                }
                label="Refresh Role"
              />
            </FormGroup>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <FormControl fullWidth>
              <InputLabel id="edit-super-user-allowed-regions-label">
                Allowed Regions
              </InputLabel>
              <Select
                labelId="edit-super-user-allowed-regions-label"
                id="edit-super-user-allowed-regions"
                multiple
                value={adminAllowedRegions}
                onChange={(e) => setAdminAllowedRegions(e.target.value)}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {US_REGION_LIST.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleEditSave}
            style={{ margin: '5px 10px 15px 0' }}
            disabled={isEditing}
          >
            {isEditing ? (
              <>
                <span>Please Wait...</span>
                <Spinner
                  color="dark"
                  style={{ height: '25px', width: '25px' }}
                />
              </>
            ) : (
              'Save Changes'
            )}
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SuperUserList;
