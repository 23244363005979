import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material';
import { useDispatch } from 'react-redux';
// import { addMonths, format } from 'date-fns';
import { addHours, addMonths, format, addDays } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FilterModal from '../components/Filter';
import FaqsTagsList from '../components/FaqsTagsList';
import faqsApi from '../api/faqs.api';

function CreateFaqPopup({ open, onClose, onSave }) {
  const [faq, setFaq] = useState({
    label: '',
    value: '',
    appRegion: process.env.REACT_APP_DEFAULT_REGION,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaq({ ...faq, [name]: value });
  };

  const handleSave = () => {
    onSave(faq); // Call the function passed from parent to save the FAQ data
    onClose(); // Close the popup after saving
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New FAQ Tag</DialogTitle>
      <DialogContent>
        <TextField
          name="label"
          label="Label"
          fullWidth
          value={faq.label}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          name="value"
          label="Value"
          fullWidth
          value={faq.value}
          onChange={handleInputChange}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FaqsTags() {
  const [filter, setFilter] = useState({});
  const [filterDisplay, setFilterDisplay] = useState('');
  const [filterBeforeDate, setFilterBeforeDate] = useState(null);
  const [filterAfterDate, setFilterAfterDate] = useState(null);
  const [applyQuickFilter, setApplyQuickFilter] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const dispatch = useDispatch();

  const handleCreateFaq = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleSaveFaq = async (faqData) => {
    try {
      await faqsApi.createFaqsTags(dispatch, faqData);
      setSnackbarMessage('FAQ Tag added successfully');
      setSnackbarOpen(true);
      setOpenPopup(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setSnackbarMessage('Failed to add FAQ Tag');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const saveFilters = () => {
    const newFilter = {};
    const displays = [];
    let dateDisplay = '';

    if (filterAfterDate) {
      const afterDateIso = new Date(filterAfterDate).toISOString(); // Convert to ISO string
      newFilter.createdAt = { $gte: afterDateIso };
      dateDisplay = `Dates: ${format(new Date(filterAfterDate), 'MM/dd/yyyy')}`;
    }

    if (filterBeforeDate) {
      const beforeDateIso = new Date(filterBeforeDate).toISOString(); // Convert to ISO string
      newFilter.createdAt = {
        ...newFilter.createdAt,
        $lte: beforeDateIso,
      };
      if (!dateDisplay) {
        dateDisplay = `Dates: -${format(
          new Date(filterBeforeDate),
          'MM/dd/yyyy'
        )}`;
      } else {
        dateDisplay = `${dateDisplay}-${format(
          new Date(filterBeforeDate),
          'MM/dd/yyyy'
        )}`;
      }
    }

    if (dateDisplay) {
      displays.push(dateDisplay);
    }

    setFilter(newFilter);
    setFilterDisplay(displays.join(' '));
  };

  useEffect(() => {
    if (applyQuickFilter && filterAfterDate) {
      setApplyQuickFilter(false);
      saveFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyQuickFilter, filterAfterDate]);

  useEffect(() => {
    if (applyQuickFilter) {
      setApplyQuickFilter(false);
      saveFilters();
    }
  }, []);

  const setLastDayFilter = () => {
    setFilterAfterDate(addHours(new Date(), -24));
    setApplyQuickFilter(true);
  };

  const setLastMonthFilter = () => {
    setFilterAfterDate(addMonths(new Date(), -1));
    setApplyQuickFilter(true);
  };

  const setLastSevenDays = () => {
    setFilterAfterDate(addDays(new Date(), -7));
    setApplyQuickFilter(true);
  };

  const resetFilters = () => {
    setFilter({});
    setFilterAfterDate(null);
    setFilterBeforeDate(null);
    setFilterDisplay('');
  };

  return (
    <div>
      <div>
        <h5 className="d-flex align-content-center align-items-center mb-0">
          <div className="flex-grow-1" />

          {filter && !Object.keys(filter).length && (
            <>
              <Button
                color="primary"
                onClick={handleCreateFaq}
                variant="contained"
                sx={{
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                  textTransform: 'none',
                }}
              >
                Create FAQ Tag
              </Button>

              <CreateFaqPopup
                open={openPopup}
                onClose={handleClosePopup}
                onSave={handleSaveFaq}
                currentUser
              />
              <Button color="primary" onClick={setLastDayFilter}>
                Last 24 Hours
              </Button>
              <Button color="primary" onClick={setLastSevenDays}>
                Last 7 Days
              </Button>
              <Button color="primary" onClick={setLastMonthFilter}>
                Last month
              </Button>
            </>
          )}

          <FilterModal
            currentFilters={filter}
            onSave={saveFilters}
            itemName="Stats"
            resetFilters={resetFilters}
            filterDisplay={filterDisplay}
          >
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="After Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="after-date-filter"
                  value={filterAfterDate}
                  onChange={setFilterAfterDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  utils={DateFnsUtils}
                  label="Before Date (including)"
                  variant="inline"
                  className="mr-2"
                  fullWidth
                  id="before-date-filter"
                  value={filterBeforeDate}
                  onChange={setFilterBeforeDate}
                  autoOk
                />
              </LocalizationProvider>
            </div>
          </FilterModal>
        </h5>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="row my-2 ">
        <FaqsTagsList filter={filter} viewHeight="75vh" />
      </div>
    </div>
  );
}

export default FaqsTags;
