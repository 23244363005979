import api from '.';

const REPORT_URL = `/admin/report`;

const getTruStageInvitations = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/tru-stage/invites`,
    null,
    params
  );
};

const getTruStageDashboardStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/tru-stage-dashboard-stats`,
    null,
    params
  );
};

const getTruStageDashboardTotalStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/tru-stage-dashboard-total-stats`,
    null,
    params
  );
};

const getTruStageDashboardInvitationByDealer = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/tru-stage/invites-by-dealers`,
    null,
    params
  );
};

export default {
  getTruStageInvitations,
  getTruStageDashboardStats,
  getTruStageDashboardTotalStats,
  getTruStageDashboardInvitationByDealer,
};
