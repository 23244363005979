import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
  Tooltip,
} from '@mui/material';
import {
  OpenInNew,
  DeleteForeverSharp,
  PlayCircleFilledWhiteSharp,
} from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import subscriptionsApi from '../api/subscriptions.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>Schedule Jobs</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function ScheduleJobsList({ filter, viewHeight = '30vh' }) {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmRun, setOpenConfirmRun] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });

  const handleRun = (row) => {
    setSelectedRow(row);
    setOpenConfirmRun(true);
  };

  const handleDelete = (row) => {
    setSelectedRow(row);
    setOpenConfirmDelete(true);
  };
  const columns = [
    {
      headerName: 'Id',
      field: 'Id',
      // headerClassName: 'custom-dark-theme--header',
      renderCell: (params) => {
        return (
          <div>
            <JSONViewer title="Payload" jsonData={params.row.payload} />
            {params.row.id}
          </div>
        );
      },
      width: 350,
    },
    {
      headerName: 'Type',
      render: (e) => e.type,
      field: 'type',
      headerClassName: 'custom-dark-theme--header',
      width: 300,
    },
    {
      headerName: 'Schedule Time',
      render: (e) => e.scheduledTime,
      field: 'scheduledTime',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'createdAt',
      render: (e) => e.createdAt,
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
    },
    {
      headerName: 'Details',
      field: 'details',
      renderCell: (params) =>
        params.row.type === 'RECURRING_PAYMENT_FAILURE_EMAIL' ? (
          <IconButton>
            <OpenInNew />
          </IconButton>
        ) : null,
      width: 90,
      disableColumnMenu: true,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Tooltip title="Run" arrow>
            <IconButton onClick={() => handleRun(params.row)} color="primary">
              <PlayCircleFilledWhiteSharp />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleDelete(params.row)}
              color="secondary"
            >
              <DeleteForeverSharp />
            </IconButton>
          </Tooltip>
        </div>
      ),
      disableColumnMenu: true,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const _updatedDataSource = data.map((item, index) => {
      return { ...item, _id: index }; // Generate new ids based on the current data only
    });
    setDataSource(_updatedDataSource); // Set the new data directly
  };

  const fetchReport = async () => {
    const params = {
      page: page + 1,
      limit: pageSize,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);
    return subscriptionsApi.getScheduleJobs(dispatch, params);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.data.totalItems;
        const currentPageRecords = reports.data.data;
        updateDataSource(currentPageRecords);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const goToOfferDetails = (detailOfferId) => {
    window.open(`/offers/${detailOfferId}`, '_blank');
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'details') {
      goToOfferDetails(cellParams.row.payload.offerId);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const confirmRun = async () => {
    try {
      if (selectedRow) {
        const response = await subscriptionsApi.runScheduledJob(
          dispatch,
          selectedRow.id
        );
        setOpenConfirmRun(false);
        if (response.success) {
          setSnackbar({
            open: true,
            message: 'Job ran successfully!',
            severity: 'success',
          });
        } else {
          // Handle cases where the response does not indicate success
          setSnackbar({
            open: true,
            message: `Job failed to run. ${response.message}.`,
            severity: 'error',
          });
        }
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to run the job.',
        severity: 'error',
      });
    }
  };

  const confirmDelete = async () => {
    try {
      if (selectedRow) {
        await subscriptionsApi.deleteScheduledJob(dispatch, selectedRow.id);
        setOpenConfirmDelete(false);
        setSnackbar({
          open: true,
          message: 'Job deleted successfully!',
          severity: 'success',
        });
        window.location.reload();
        // Optionally, refresh data here
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete the job.',
        severity: 'error',
      });
    }
  };
  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            // paginationMode="server"
            onPaginationModelChange={handlePageChange}
            onCellClick={handleCellClick}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
      {/* Run Confirmation Dialog */}
      <Dialog open={openConfirmRun} onClose={() => setOpenConfirmRun(false)}>
        <DialogTitle>Are you sure you want to run this job?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmRun(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRun} color="primary">
            Run
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      >
        <DialogTitle>Are you sure you want to delete this job?</DialogTitle>
        <DialogContent>
          <JSONViewer
            title="Payload"
            jsonData={selectedRow && selectedRow.payload}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              snackbar.severity === 'error' ? '#ff4c4c' : 'green',
            color: snackbar.severity === 'error' ? '#fefefe' : 'white',
            textAlign: 'center',
          },
        }}
      />
    </div>
  );
}

export default ScheduleJobsList;
