import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { Route, Routes } from 'react-router-dom';

import Cars from '../containers/Cars';
import Offers from '../containers/Offers';
import Car from '../containers/Car';
import Error from './Error';
import Users from '../containers/Users';
import User from '../containers/User';
import api from '../api';
import {
  getUserMe,
  getUserPermissions,
} from '../store/actions/userGroup.actions';
import AdminNavBar from './AdminNavBar';
import Dashboard from '../containers/Dashboard';
import DcrPage from '../containers/dcr/DcrPage';
import CarBackgroundImages from '../containers/CarBackgroundImages';
import Errors from '../containers/Errors';
import ErrorDetails from '../containers/ErrorDetails';
import DealerCodes from '../containers/DealerCodes';
import SMSLogs from '../containers/SMSLogs';
import Reports from '../containers/Reports';
import CreditUnionReports from '../containers/CreditUnionReports';
import Invitations from '../containers/Invitations';
import Status from '../containers/Status';
import AppConfig from '../containers/AppConfig';
import SubscriptionAppConfig from '../containers/SubscriptionAppConfig';
import InvitationLogs from '../containers/InvitationLogs';
import SendInvitation from '../containers/SendInvitation';
import BulkInvitation from '../containers/BulkInvitation';
import InvitationStats from '../containers/InvitationStats';
import BroadcastMessage from '../containers/BroadcastMessage';
import LockedCars from '../containers/LockedCars';
import CarIssues from './CarIssues';
import OperationStats from '../containers/OperationStats';
import EmptyDashboard from '../containers/EmptyDashboard';
import NoPermission from '../containers/NoPermission';
import BusinessConfig from '../containers/BusinessConig/BusinessConfig';
import ContactUsFailedRequests from '../containers/ContactUsFailedRequests';
import SubscriptionsReport from '../containers/SubscriptionsReport';
import SubscriptionsDealerReport from '../containers/SubscriptionsDealerReport';
import SubscriptionErrors from '../containers/SubscriptionErrors';
import SupportReport from '../containers/SupportReport';
import WelcomeKitReport from '../containers/WelcomeKitReport';
import WelcomeKitDashboard from '../containers/WelcomeKitDashboard';
import ClientsStats from '../containers/ClientsStats';
import OfferDetails from '../containers/OfferDetails';
import Transactions from '../containers/Transactions';
import PaymentResponses from '../containers/PaymentResponses';
import OfferLogs from '../containers/OfferLogs';
import ScheduleJobs from '../containers/ScheduleJobs';
import ArchiveScheduleJobs from '../containers/ArchiveScheduleJobs';
import RecurringJourneyLogs from '../containers/RecurringJourneyLogs';
import QuotesList from '../containers/QuotesList';
import ApiErrors from '../containers/ApiErrors';
import QueryEditor from '../containers/QueryEditor';
import FileProcessingLogs from '../containers/FileProcessingLogs';
import SubscriptionCancellations from '../containers/SubscriptionCancellations';
import RepairBookingDashboard from '../containers/RepairBookingDashboard';
import ClaimUpdatesPage from '../containers/ClaimUpdatesPage';
import UpdateCallbacksPage from '../containers/UpdateCallbacksPage';
import ConversationDashboard from '../containers/ConversationDashboard';
import SubscriptionStatus from '../containers/SubscriptionStatus';
import RecurringFileLogs from '../containers/RecurringFileLogs';
import TruStageDashboard from '../containers/TruStageDashboard';
import TruStageInvitationsList from '../containers/TruStageInvitationsList';
import SuperUserManager from '../containers/SuperUserManager';
import SubscriptionCallbacksPage from '../containers/SubscriptionCallbacksPage';
import VCPScheduleJobs from '../containers/VCPScheduledJobs';
import FaqsQuesAnswer from '../containers/FaqsQuesAnswer';
import FaqsTags from '../containers/FaqsTags';
import VCPScheduleJobArchives from '../containers/VCPScheduledJobArchives';
import VcpMuleApiLogsPage from '../containers/VcpMuleApiLogsPage';
import VcpSubscriptionMuleApiLogsPage from '../containers/VcpSubscriptionMuleApiLogsPage';

function AppIntermediate() {
  const { authState } = useOktaAuth();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.permissions);
  // eslint-disable-next-line no-unused-vars
  const currentUser = useSelector((state) => state.currentUser.currentUser);

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const { accessToken } = authState;

      const fetchPermissions = async () => {
        await dispatch(getUserMe());
        await dispatch(getUserPermissions());
      };

      if (accessToken) {
        api.setApiToken(accessToken);
        fetchPermissions();
      }
    }
  }, [authState]);

  if (authState && !authState.isAuthenticated) {
    return (
      <>
        <AdminNavBar />
        <Grid style={{ marginTop: '3rem' }} container spacing={3}>
          <Routes>
            <Route
              path="/"
              exact
              // eslint-disable-next-line react/no-unstable-nested-components
              Component={() => <div className="p-4">Sign in to continue</div>}
            />
          </Routes>
        </Grid>
      </>
    );
  }
  if (!permissions) {
    return (
      <>
        <AdminNavBar />
        <EmptyDashboard />
      </>
    );
  }

  if (permissions) {
    const hasSomePerm =
      Object.keys(permissions).filter((perm) => {
        return permissions[perm].READ || permissions[perm].WRITE;
      }).length > 0;
    if (!hasSomePerm)
      return (
        <>
          <AdminNavBar />
          <NoPermission />
        </>
      );
  }

  return (
    <>
      <AdminNavBar />

      <Grid style={{ marginTop: '3rem' }} container spacing={3}>
        <Grid item md={12}>
          <Error />
        </Grid>
        <Grid item md={12} style={{ paddingLeft: 50, paddingRight: 50 }}>
          <Routes>
            {permissions && permissions.USERS && permissions.USERS.READ && (
              <>
                <Route path="/users" exact element={<Users />} />
                <Route path="/users/:id" exact element={<User />} />
                <Route path="/users/:userId/cars" exact element={<Cars />} />
                <Route path="/users/:userId/cars/:id" exact element={<Car />} />
              </>
            )}
            {permissions && permissions.CARS && permissions.CARS.READ && (
              <>
                <Route path="/cars" exact element={<Cars />} />
                <Route path="/cars/:id" exact element={<Car />} />
              </>
            )}
            {permissions &&
              permissions.OFFERS_DATA &&
              permissions.OFFERS_DATA.READ && (
                <>
                  <Route
                    path="/file-logs"
                    exact
                    element={<FileProcessingLogs />}
                  />
                  <Route path="/offers" exact element={<Offers />} />
                  <Route path="/offers/:id" exact element={<OfferDetails />} />
                  <Route
                    path="/transactions"
                    exact
                    element={<Transactions />}
                  />
                  <Route
                    path="/subscription-cancellations"
                    exact
                    element={<SubscriptionCancellations />}
                  />
                  <Route
                    path="/payment-responses"
                    exact
                    element={<PaymentResponses />}
                  />
                  <Route path="/offer-logs" exact element={<OfferLogs />} />
                  <Route
                    path="/recurring-file-logs"
                    exact
                    element={<RecurringFileLogs />}
                  />
                  <Route
                    path="/recurring-journey-logs"
                    exact
                    element={<RecurringJourneyLogs />}
                  />
                  <Route
                    path="/schedule-jobs"
                    exact
                    element={<ScheduleJobs />}
                  />
                  <Route
                    path="/archive-schedule-jobs"
                    exact
                    element={<ArchiveScheduleJobs />}
                  />
                  <Route path="/quotes-list" exact element={<QuotesList />} />
                  <Route path="/api-errors" exact element={<ApiErrors />} />
                  <Route
                    path="/subscriptionStatus"
                    exact
                    element={<SubscriptionStatus />}
                  />
                  <Route
                    path="/subscription-app-config"
                    exact
                    element={<SubscriptionAppConfig />}
                  />
                </>
              )}
            {permissions && permissions.SUPPORT && permissions.SUPPORT.READ && (
              <Route
                path="/subscription-callback-logs"
                exact
                element={<SubscriptionCallbacksPage />}
              />
            )}
            {permissions &&
              permissions.OPERATIONAL_STATS &&
              permissions.OPERATIONAL_STATS.WRITE && (
                <Route
                  path="/vcp-scheduled-jobs"
                  exact
                  element={<VCPScheduleJobs />}
                />
              )}
            {permissions &&
              permissions.OPERATIONAL_STATS &&
              permissions.OPERATIONAL_STATS.WRITE && (
                <Route
                  path="/faqs-questions"
                  exact
                  element={<FaqsQuesAnswer />}
                />
              )}
            {permissions &&
              permissions.OPERATIONAL_STATS &&
              permissions.OPERATIONAL_STATS.WRITE && (
                <Route path="/faqs-tags" exact element={<FaqsTags />} />
              )}
            {permissions &&
              permissions.OPERATIONAL_STATS &&
              permissions.OPERATIONAL_STATS.WRITE && (
                <Route
                  path="/vcp-scheduled-job-archives"
                  exact
                  element={<VCPScheduleJobArchives />}
                />
              )}
            {permissions &&
              permissions.SQL_EDITOR &&
              permissions.SQL_EDITOR.WRITE && (
                <Route path="/query-editor" exact element={<QueryEditor />} />
              )}
            {permissions &&
              permissions.CLIENTS_STATS &&
              permissions.CLIENTS_STATS.READ && (
                <Route path="/clients-stats" exact element={<ClientsStats />} />
              )}
            {permissions &&
              permissions.DEALER_PROFILES &&
              permissions.DEALER_PROFILES.READ && (
                <Route path="/dealer-portal" exact element={<DcrPage />} />
              )}
            {permissions &&
              permissions.INVITATIONS &&
              permissions.INVITATIONS.READ && (
                <Route path="/invitations" exact element={<Invitations />} />
              )}
            {permissions &&
              permissions.LOCKED_CARS &&
              permissions.LOCKED_CARS.READ && (
                <Route path="/lockedCars" exact element={<LockedCars />} />
              )}
            {permissions &&
              permissions.CARS_WITH_DATA_ISSUES &&
              permissions.CARS_WITH_DATA_ISSUES.READ && (
                <Route path="/carIssues" exact element={<CarIssues />} />
              )}
            {permissions &&
              permissions.INVITATIONS_LOGS &&
              permissions.INVITATIONS_LOGS.READ && (
                <Route
                  path="/invitation-logs"
                  exact
                  element={<InvitationLogs />}
                />
              )}
            {permissions &&
              permissions.ERROR_LOGS &&
              permissions.ERROR_LOGS.READ && (
                <>
                  <Route path="/errors" exact element={<Errors />} />
                  <Route path="/errors/details" element={<ErrorDetails />} />
                </>
              )}
            {permissions &&
              permissions.USER_REPORTS &&
              permissions.USER_REPORTS.READ && (
                <Route path="/reports" exact element={<Reports />} />
              )}
            {permissions &&
              permissions.CREDIT_UNION_REPORTS &&
              permissions.CREDIT_UNION_REPORTS.READ && (
                <Route
                  path="/creditunionreports"
                  exact
                  element={<CreditUnionReports />}
                />
              )}
            {permissions &&
              permissions.RENEWALS &&
              permissions.RENEWALS.READ && (
                <Route
                  path="/subscriptionsReport"
                  exact
                  element={<SubscriptionsReport />}
                />
              )}
            {permissions &&
              permissions.RENEWALS &&
              permissions.RENEWALS.READ && (
                <Route
                  path="/subscriptionDealerReport"
                  exact
                  element={<SubscriptionsDealerReport />}
                />
              )}
            {permissions &&
              permissions.RENEWALS &&
              permissions.RENEWALS.READ && (
                <Route
                  path="/subscriptionErrors"
                  exact
                  element={<SubscriptionErrors />}
                />
              )}
            {permissions &&
              permissions.WELCOME_KIT_REPORTS &&
              permissions.WELCOME_KIT_REPORTS.READ && (
                <Route
                  path="/welcomekitinvitations"
                  exact
                  element={<WelcomeKitReport />}
                />
              )}
            {permissions &&
              permissions.WELCOME_KIT_REPORTS &&
              permissions.WELCOME_KIT_REPORTS.READ && (
                <Route
                  path="/welcomekitdashboard"
                  exact
                  element={<WelcomeKitDashboard />}
                />
              )}

            {permissions &&
              permissions.WELCOME_KIT_REPORTS &&
              permissions.WELCOME_KIT_REPORTS.READ && (
                <Route
                  path="/tru-stage-invitations"
                  exact
                  element={<TruStageInvitationsList />}
                />
              )}
            {permissions &&
              permissions.WELCOME_KIT_REPORTS &&
              permissions.WELCOME_KIT_REPORTS.READ && (
                <Route
                  path="/tru-stage-dashboard"
                  exact
                  element={<TruStageDashboard />}
                />
              )}

            {permissions &&
              permissions.ADMIN_MANUAL_INVITATION &&
              permissions.ADMIN_MANUAL_INVITATION.WRITE && (
                <Route
                  path="/sendInvitation"
                  exact
                  element={<SendInvitation />}
                />
              )}
            {permissions &&
              permissions.CAR_BACKGROUNDS &&
              permissions.CAR_BACKGROUNDS.READ && (
                <Route
                  path="/car-backgrounds"
                  exact
                  element={<CarBackgroundImages />}
                />
              )}
            {permissions &&
              permissions.BROADCAST_MESSAGE &&
              permissions.BROADCAST_MESSAGE.READ && (
                <Route
                  path="/sendBroadcast"
                  exact
                  element={<BroadcastMessage />}
                />
              )}
            {permissions &&
              permissions.DEALER_QR_CODE &&
              permissions.DEALER_QR_CODE.READ && (
                <Route path="/dealerCodes" exact element={<DealerCodes />} />
              )}
            {permissions &&
              permissions.APP_CONFIG &&
              permissions.APP_CONFIG.READ && (
                <Route path="/app-config" exact element={<AppConfig />} />
              )}
            {permissions &&
              permissions.BUSINESS_CONFIG &&
              permissions.BUSINESS_CONFIG.READ && (
                <Route
                  path="/business-config"
                  exact
                  element={<BusinessConfig />}
                />
              )}
            {permissions &&
              permissions.OPERATIONAL_STATS &&
              permissions.OPERATIONAL_STATS.READ && (
                <Route
                  path="/operationalStats"
                  exact
                  element={<OperationStats />}
                />
              )}
            {permissions &&
              permissions.BULK_INVITATION &&
              permissions.BULK_INVITATION.READ && (
                <>
                  <Route
                    path="/bulk-invitations"
                    exact
                    element={<BulkInvitation />}
                  />
                  <Route
                    path="/invitationStats"
                    exact
                    element={<InvitationStats />}
                  />
                </>
              )}
            {permissions &&
              permissions.SMS_LOGS &&
              permissions.SMS_LOGS.READ && (
                <Route path="/smsLogs" exact element={<SMSLogs />} />
              )}
            {permissions &&
              permissions.CONTACT_US &&
              permissions.CONTACT_US.READ && (
                <Route
                  path="/contactUs/failed-requests"
                  exact
                  element={<ContactUsFailedRequests />}
                />
              )}
            {permissions && permissions.SUPPORT && permissions.SUPPORT.READ && (
              <>
                <Route
                  path="/support/stats"
                  exact
                  element={<SupportReport />}
                />
                <Route
                  path="/support/conversations"
                  exact
                  element={<ConversationDashboard />}
                />
                <Route
                  path="/repair-bookings/dashboard"
                  exact
                  element={<RepairBookingDashboard />}
                />
                <Route
                  path="/logs/vcp-mule-api-logs"
                  exact
                  element={<VcpMuleApiLogsPage />}
                />
                <Route
                  path="/logs/vcp-subscription-mule-api-logs"
                  exact
                  element={<VcpSubscriptionMuleApiLogsPage />}
                />
              </>
            )}
            {permissions &&
              permissions.DASHBOARD &&
              permissions.DASHBOARD.READ && (
                <>
                  <Route
                    path="/updates/glowClaimUpdates"
                    exact
                    element={<ClaimUpdatesPage />}
                  />
                  <Route
                    path="/updates/glowUpdateCallbacks"
                    exact
                    element={<UpdateCallbacksPage />}
                  />
                </>
              )}

            {permissions &&
              permissions.SUPER_ADMIN &&
              permissions.SUPER_ADMIN.READ && (
                <>
                  <Route
                    path="/manage/admin"
                    exact
                    element={<SuperUserManager />}
                  />
                  <Route
                    path="/support/conversations"
                    exact
                    element={<ConversationDashboard />}
                  />
                </>
              )}

            <Route path="/status" exact element={<Status />} />
            <Route path="/" exact element={<Dashboard />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
          </Routes>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: '3rem' }} />
    </>
  );
}

export default AppIntermediate;
