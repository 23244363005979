import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import {
  CancelRounded,
  CheckCircleRounded,
  ErrorOutline,
} from '@mui/icons-material';
import moment from 'moment';
import { PAGINATION_OPTIONS } from '../constants';
import JSONViewer from './JSONViewer';
import reportApi from '../api/report.api';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function CancelStatusCell({ row }) {
  const getStatusIcon = (identifier, title) => {
    return (
      <Tooltip title={title} arrow>
        <div>
          {row[identifier] ? (
            row[identifier].endsWith('SUCCESS') ? (
              <CheckCircleRounded style={{ fill: 'green' }} />
            ) : (
              <CancelRounded style={{ fill: 'red' }} />
            )
          ) : (
            <ErrorOutline style={{ fill: 'gray' }} />
          )}
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      {getStatusIcon('cancelQuoteStatus', 'Cancel Quote')}
      <span style={{ fontWeight: 'normal', color: 'green', fontSize: '12px' }}>
        -
      </span>
      {getStatusIcon('refundStatus', 'Refund')}
      <span style={{ fontWeight: 'normal', color: 'green', fontSize: '12px' }}>
        -
      </span>
      {getStatusIcon('cancelContractStatus', 'Cancel Contract')}
    </>
  );
}

function CancellationList({ viewHeight = '50vh' }) {
  const columns = [
    {
      headerName: 'Date',
      renderCell: (c) =>
        moment(c.value).format(process.env.REACT_APP_DATE_TIME_FORMAT),
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 160,
    },
    {
      headerName: 'Contract #',
      render: (e) => e.contractNumber,
      field: 'contractNumber',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'VIN',
      render: (e) => e.vin,
      field: 'vin',
      headerClassName: 'custom-dark-theme--header',
      width: 180,
    },
    {
      headerName: 'Reason',
      render: (e) => e.cancelReason,
      field: 'cancelReason',
      headerClassName: 'custom-dark-theme--header',
      width: 270,
    },
    {
      headerName: 'Refund',
      render: (e) => e.refundAmount,
      field: 'refundAmount',
      headerClassName: 'custom-dark-theme--header',
      width: 90,
    },
    {
      headerName: 'Status',
      renderCell: (e) => <CancelStatusCell row={e.row} />,
      field: 'cancelContractStatus',
      headerClassName: 'custom-dark-theme--header',
      width: 140,
    },
    {
      headerName: 'Quote',
      renderCell: (c) => {
        return (
          <>
            <JSONViewer
              title="Payload"
              color="primary"
              jsonData={
                c.row.cancelQuotePayload ? c.row.cancelQuotePayload : ''
              }
            />
            <JSONViewer
              title="Response"
              color="secondary"
              jsonData={
                c.row.cancelQuoteResponse ? c.row.cancelQuoteResponse : ''
              }
            />
          </>
        );
      },
      field: 'cancelQuotePayload',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'Refund',
      renderCell: (c) => {
        return (
          <>
            <JSONViewer
              title="Payload"
              color="primary"
              jsonData={c.row.refundPayload ? c.row.refundPayload : ''}
            />
            <JSONViewer
              title="Response"
              color="secondary"
              jsonData={c.row.refundResponse ? c.row.refundResponse : ''}
            />
          </>
        );
      },
      field: 'refundPayload',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
    {
      headerName: 'Contract',
      renderCell: (c) => {
        return (
          <>
            <JSONViewer
              title="Payload"
              color="primary"
              jsonData={
                c.row.cancelContractPayload ? c.row.cancelContractPayload : ''
              }
            />
            <JSONViewer
              title="Response"
              color="secondary"
              jsonData={
                c.row.cancelContractResponse ? c.row.cancelContractResponse : ''
              }
            />
          </>
        );
      },
      field: 'cancelContractPayload',
      headerClassName: 'custom-dark-theme--header',
      width: 120,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const updateDataSource = (data) => {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._id + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _id: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  };

  const fetchReport = async (skip = dataSource.length, limit = pageSize) => {
    const params = {
      skip,
      limit,
      filter: {},
    };
    setIsLoading(true);

    return reportApi.getCancellationList(params, dispatch);
  };

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        updateDataSource(reports.data);
        setRowCount(reports.totalCount);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize]);

  const handlePageChange = (params) => {
    setPageSize(params.pageSize);
    setPage(params.page);
  };

  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pageSizeOptions={PAGINATION_OPTIONS}
            onPaginationModelChange={handlePageChange}
            pagination
            rowCount={rowCount}
            getRowId={(row) => row._id}
            rows={dataSource}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CancellationList;
