import React from 'react';
import VCPScheduleJobArchivesList from '../components/VCPScheduledJobArchivesList';

function VCPScheduleJobArchives() {
  return (
    <div>
      <div className="row my-2 ">
        <VCPScheduleJobArchivesList viewHeight="75vh" />
      </div>
    </div>
  );
}

export default VCPScheduleJobArchives;
